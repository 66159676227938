@import "../../scss/main";

.modal {
  &-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //background-color: rgba($black, .9);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-content {
    padding: 20px;
    border-radius: 10px;
    background-color: rgba($black, 1);
  }
}