@import "var";
@import "button";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 16px;
  font-family: $primaryFont;
  line-height: 1.5;
}

body {
  background-color: $dark;
}

section {
  padding: 100px 0;
}

h1 {
  color: $light;
}

h2 {
  color: $yellow;
  text-transform: uppercase;
  font-size: 2.5rem;
  margin: 0 0 80px;
  text-align: center;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

input, textarea {
  border: none;
  outline: none;
  font-family: $primaryFont;
  border-radius: 10px;

  &[type="file"] {
    display: none;
  }
}

.container {
  width: 70%;
  margin: 0 auto;
  @media (max-width: 1080px) {
    width: 90%;
  }
}


.form {
  &-checkbox {
    display: flex;
    user-select: none;
    padding: 3px;
    border-radius: 5px;

    input {
      height: 1.3em;
      width: 1.3em;
      margin-right: 5px;
    }

    label {
      flex: 1;
      cursor: pointer;
    }
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes zoomOut {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}
