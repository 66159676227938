@import "./var";

.btn {
  background-color: transparent;
  padding: 0.3em 1.5em;
  cursor: pointer;
  font-size: 1em;
  transition: $transition;
  border-radius: 10px;

  &-sm {
    padding: 0.1em 1em;
    border-radius: 5px;
  }

  &:hover {
    background-color: $danger;
    color: $light;
    border: 1px solid $danger;
  }

  &-danger {
    border: 1px solid $danger;
    color: $danger;
  }

  &-warning {
    border: 1px solid $yellow;
    color: $yellow;
  }

  &-success {
    border: 1px solid $green;
    color: $light;

    &:hover {
      background-color: $green;
      color: $light;
      border: 1px solid $green;
    }
  }

  &-bg-warning {
    background-color: $yellow;
    color: $light;
  }

  &-bg-success {
    background-color: $green;
    color: $light;

    &:hover {
      background-color: rgba($green, 0.7);
      color: $light;
      border: 1px solid $green;
    }
  }

  &-bg-danger {
    background-color: $danger;
    color: $light;

    &:hover {
      background-color: rgba($danger, 0.7);
      color: $light;
      border: 1px solid $danger;
    }
  }
}
