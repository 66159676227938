@import "../../scss/main";

.skills {
  &-wrapper {
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      margin-bottom: 10px;
    }
  }

  &-icon {
    $size: 150px;
    display: grid;
    grid-template-columns: repeat(5, $size);
    grid-auto-rows: $size;
    justify-content: center;
    gap: 10px;
    margin-bottom: 80px;
    @media (max-width: 1200px) {
      grid-template-columns: repeat(auto-fit, $size);
    }


    div {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 10px;

      box-shadow: 0 0 10px 3px rgba($light, .05) inset;

      span {
        flex: 1;
        text-align: center;
      }


      svg {
        width: auto;
        height: 100%;
      }

      p {
        font-size: 1.2rem;
        text-align: center;
        color: $green;
      }
    }
  }
}