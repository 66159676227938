@import "../../scss/main";

footer {
  padding: 50px 0;
  //background-color: $black;
  color: $light;
  font-family: $secondaryFont;
  text-align: center;
  background: linear-gradient(0deg, rgba($black, .99), rgba($black, .5)), url("../../assets/home-header-bg.jpg") fixed center;

  .social-wrapper {
    justify-content: center;

    a {
      color: $light;

    }
  }

  a {
    color: $yellow;
  }
}