@import "../../scss/main";

.userInfo-wrapper {
  .dashboard-content {
    display: grid;
    grid-template-columns: 200px auto;
    gap: 20px;
  }

  input {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: textfield;
    }
  }

  .profile {
    &-image {
      position: relative;
      overflow: hidden;
      width: 200px;
      height: 200px;

      img {
        border-radius: 50%;
      }

      &:hover {
        .input-group {
          transform: translateY(-100%);
        }
      }

      .btn {
        width: 48%;
        margin-right: 4%;

        &:last-child {
          margin-right: 0;
        }
      }

      img {
        display: block;
      }

      input {
        display: none;
      }

      .input-group {
        position: absolute;
        width: 100%;
        top: 100%;
        left: 0;
        padding: 10px;
        transition: transform $transition;
        background-color: rgba($black, .9);
      }
    }

    &-info {
      line-height: 2;

      span {
        font-weight: 600;
      }
    }
  }

  .form {
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: 1fr 1fr 2fr 1fr;
    gap: 2%;
    max-width: 720px;

    &-group {
      @include form-group(rgba($black, .9));
      margin-bottom: 20px;
      min-width: 120px;
      flex-grow: 1;

      &-fn {
        width: 49%;
      }

      &-ln {
        width: 49%;
      }

      &-email {
        width: 60%;
      }

      &-exp {
        width: 18%;
      }

      &-age {
        width: 18%;
      }


      &-about {
        width: 100%;
        min-height: 200px;
      }

      &-btn {
        width: 100%;

        .btn {
          float: right;
          margin-left: 10px;
        }
      }
    }
  }

}