@import "../../scss/main";

.social-wrapper {
  display: flex;
  gap: 10px;
  width: 100%;
  margin: 20px 0;

  a {
    border: 1px solid $gray;
    font-size: 1.3rem;
    height: 2em;
    width: 2em;
    text-align: center;
    line-height: 2em;
    transition: $transition;

    &:hover {
      background-color: $light;
      color: $gray;
    }
  }
}