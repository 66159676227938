@import "../../scss/main";

.login-wrapper {
  height: 100vh;
  max-width: 600px;
  margin: 0 auto;

  .form-group {
    @include form-group();
    margin-bottom: 20px;

  }

  button {
    width: 100%;
  }
}