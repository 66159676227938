@import "../../scss/main.scss";

.progress-wrapper {
  background: linear-gradient(rgba($dark, .9), rgba($dark, .9)), url("../../assets/cta.jpg") fixed center;

  .content {
    overflow: hidden;

    .slide-progress {
      width: 200%;
      display: flex;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;

      & > div {
        flex: 1;
        padding: 10px;
        color: $light;
      }
    }
  }

  .progress-title {
    @include flex(space-between, center);
    border-bottom: 1px solid $green;
    padding-bottom: 10px;

    h3 {
      font-size: 1.5rem;
    }
  }

  .education {
    width: 100%;
  }

  .experience {
    width: 100%;
  }

  ul {
    margin-top: 30px;

    li {
      padding: 5px 10px;
      border-radius: 3px;
      margin-bottom: 20px;
      box-shadow: 0 0 3px 0px $light;
      transition: $transition;
      cursor: pointer;

      &:hover {
        background-color: rgba($danger, .1);
      }

      span {
        font-weight: 700;
      }
    }
  }
}

@keyframes moveLeft {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

@keyframes moveRight {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0%);
  }
}
