@import "../../scss/main";

.cta {
  @include flex(center, center);
  height: 50vh;
  background: linear-gradient(rgba($dark, 0.7), rgba($dark, 0.8)),
    url("../../assets/cta.jpg") fixed center;

  article {
    @include flex(center, center);
    background: rgba($black, 0.6);
    color: $light;
    gap: 50px;
    padding: 30px 60px;
    @media (max-width: 980px) {
      flex-direction: column;
      width: 80%;
      gap: 30px;
    }

    h3 {
      font-size: 2rem;
      font-weight: 400;
    }
  }
}
