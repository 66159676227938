@import "../../scss/main";

.dashboard {
  min-height: 100vh;

  &-title {
    @include flex(space-between, center);
    border-bottom: 1px solid $green;
    padding-bottom: 20px;
    margin-bottom: 20px;

    h3 {
      font-size: 1.8rem;
    }

    &-btn {
      button {
        margin-left: 10px;
      }
    }
  }


  article {
    color: $light;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 100px;

    a {
      &.active {
        color: $green;
      }
    }

    aside {
      //padding: 20px;
      line-height: 3;
    }

    div {


    }
  }
}