@import "../../scss/main";

.error-wrapper {
  height: 100vh;
  width: 100%;
  display: block;
  position: relative;

  .error-animation {
    height: 370px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 5rem;
    width: 3ch;

    button {
      position: absolute;
      font-size: 1rem;
      top: 110%;
      width: 100%;
    }
  }
  
  .flash-light {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    animation-name: lightMove;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-duration: 5s;
    transform-box: stroke-box;
    transform-origin: center bottom;
  }

  .ray {
    position: absolute;
    top: 0;
    width: 4ch;
    height: calc(100% - 50px);
    left: calc(50% - 2ch);
    background: rgba($yellow, .2);

    &:after {
      content: "";
      position: absolute;
      background-color: $dark;
      top: 0;
      height: 110%;
      right: 101%;
      width: calc(2ch);
      transform-origin: right top;
      transform: rotateZ(-17deg);
    }

    &:before {
      content: "";
      position: absolute;
      background-color: $dark;
      height: 110%;
      top: 0;
      left: 101%;
      width: calc(2ch);
      transform-origin: left top;
      transform: rotateZ(17deg);
    }
  }

  img {
    height: auto;
    width: 55px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .text {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    text-align: center;

    h2 {
      font-size: 5rem;
      color: $dark;
      line-height: 1;
      margin: 0;
      padding: 0;
    }

    p {
      color: $dark;
      font-size: 1.3rem;
    }
  }

  .light {
    height: 3ch;
    width: 4ch;
    border-radius: 50%;
    background: rgba($yellow, 1);
    position: absolute;
    top: calc(0% - 1.5ch);
    left: calc(50% - 2ch);
  }
}

@keyframes lightMove {
  0% {
    transform: rotateZ(-30deg);
  }
  50% {
    transform: rotateZ(30deg);
  }
  100% {
    transform: rotateZ(-30deg);
  }
}