@import "../../scss/main";

.navbar {
  @include flex(space-between, center);
  padding: 15px 10%;

  .menu-list {
    ul {
      color: $light;
      @include flex(flex-end, center);
      gap: 30px;

      li {
        cursor: pointer;
        transition: $transition;
        font-weight: 700;
        font-family: $secondaryFont;
        letter-spacing: 1px;

        a {
          padding: 10px 15px;
        }

        .active {
          color: $green;
        }

        &:hover {
          color: $yellow;
        }
      }
    }
  }
}