@import "../../scss/main";

.about {
  background-color: $darkLight;
  color: $light;

  &-row {
    display: grid;
    grid-template-columns: 1fr 3fr;
    font-family: $secondaryFont;
    gap: 20px;
    @media (max-width: 1064px) {
      grid-template-columns: 1fr;
    }

    &-text {
      display: grid;
      grid-template-areas: "name name" "text text" "left right";
      @media (max-width: 1280px) {
        grid-template-areas: "name name" "text text" "left left" " right right";
      }
      @media (max-width: 1064px) {
        grid-template-areas: "name name" "text text" "left right";
      }
      @media (max-width: 768px) {
        grid-template-areas: "name name" "text text" "left left" " right right";
      }
    }

    .left {
      grid-area: left;
    }

    .right {
      grid-area: right;
    }

    h3 {
      grid-area: name;
      font-size: 2rem;
      text-transform: uppercase;
      @media (max-width: 1064px) {
        text-align: center;
        line-height: 2;
      }

      span {
        color: $yellow;
      }
    }

    p {
      grid-area: text;
      line-height: 2;

      span {
        font-weight: 700;
      }
    }


    img {
      display: block;
      height: 300px;
      width: 300px;
      border-radius: 50%;
      @media (max-width: 1064px) {
        margin: 0 auto;
      }
    }
  }
}