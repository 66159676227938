@import "../../scss/main.scss";

.adminSkills-wrapper {
  .form-group {
    @include form-group();

  }

  h4 {
    margin-bottom: 20px;
    font-size: 1.5rem;
  }

  .form {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 10px;
    border-bottom: 1px solid $green;
    margin-bottom: 20px;
    padding-bottom: 20px;

    .btn {
      font-size: 1rem;

      font-family: $secondaryFont;
    }

    label {
      @include flex(center, center);
    }
  }

  .skills-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;

    &.primary {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid $green;
    }

    .skill {
      box-shadow: 0 0 5px 3px rgba($light, 0.1) inset;
      padding: 5px;
      cursor: pointer;

      &.selected {
        box-shadow: 0 0 2px 3px $danger;
      }

      div {
        padding: 5px;
        height: 150px;
        width: 150px;

        img {
          object-fit: contain;
        }
      }

      h5 {
        color: $green;
        text-align: center;
        font-size: 1.3rem;
        margin: 5px 0;
      }
    }
  }
}
