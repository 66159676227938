.typing-word {
  position: relative;

  .blink-cursor {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background-color: tomato;
    display: inline-block;
    animation-name: blinkCursor;
    animation-duration: 100ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-direction: alternate-reverse;

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: -5px;
      width: 10px;
      height: 2px;
      background-color: tomato;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -5px;
      width: 10px;
      height: 2px;
      background-color: tomato;
    }
  }
}

@keyframes blinkCursor {
  from {
    opacity: 100%;
  }
  to {
    opacity: 10%;
  }
}