@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&family=Raleway:wght@300;400;700&display=swap');

$primaryFont: 'Montserrat', sans-serif;
$secondaryFont: 'Raleway', sans-serif;

$black: #000000;
$dark: #1e2326;
$darkLight: #252b2f;
$gray: #31383d;
$grayLight: #999a9a;
$light: #ffff;
$green: #009e66;
$yellow: #df9f3b;
$danger: #c86d6f;

$transition: .4s ease

