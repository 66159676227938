@import "../../scss/var";

.projects-wrapper {
  background: $darkLight;

  &-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    justify-content: center;
    @media (max-width: 1200px) {
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
  }

  .project-card {
    border-radius: 15px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "thumbnail" "text";
    color: $light;
    background-color: $dark;
    grid-template-rows: auto 1fr;
    

    .thumbnail {
      height: 300px;
      grid-area: thumbnail;
      padding: 10px;

      div {
        position: relative;
        cursor: pointer;
        overflow: hidden;
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }

      &:hover {
        .links {
          transform: translate(0, 0);
          opacity: 1;
        }
      }

      img {

        object-position: center top;
      }

      .links {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 10px;
        background-color: rgba($black, .8);
        display: flex;
        gap: 30px;
        justify-content: center;
        align-items: center;
        transition: $transition;
        transform: translate(100%, 100%);
        opacity: 0;

        .btn {
          line-height: 1em;
          color: $light;
          font-size: 2rem;
        }
      }
    }

    .text {
      grid-area: text;
      padding: 0 10px 10px;

      .technology {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        li {
          padding: 3px 6px;
          border-radius: 5px;
          background-color: $darkLight;
          cursor: pointer;
          transition: $transition;
          font-size: 1rem;

          &:hover {
            background-color: $danger;
          }
        }
      }


    }

    h3 {
      font-size: 1.2rem;
      font-weight: 400;
      font-family: $secondaryFont;
      color: $green;
    }
  }
}