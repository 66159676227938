@import "../../scss/main";

.adminProject {
  &-wrapper {
    .dashboard-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;

    }

    .project {

      &-card {
        width: 100%;
        padding: 10px;
        background-color: $darkLight;
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 10px;
        border-radius: 10px;
        box-shadow: 0 5px 20px 0 $black;

        .thumbnail {
          height: 200px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          border-radius: 10px;

          &:hover {
            div {
              opacity: 1;
              display: flex;
            }
          }

          div {
            display: none;
            opacity: 0;
            @include flex(center, center);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba($black, .6);
            transition: opacity $transition;

            svg {
              font-size: 2rem;
            }
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center top;
          }
        }

        &-body {
          @include flex($direction: column);
          position: relative;

          span {
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
          }
        }

        &-text {
          flex: 1;
        }

        &-btn {
          @include flex(space-around, center);

          a {
            @include flex(center, center);
            background-color: $black;
            font-size: 1.3rem;
            height: 2em;
            width: 2em;
            border-radius: 50%;
            transition: $transition;

            &:hover {
              background-color: $danger;
              color: $black;
            }
          }
        }
      }

    }

  }

  &-form {
    max-width: 600px;
    width: 100%;

    .form-group {
      @include form-group($black);
      margin-bottom: 20px;
    }

    .technology {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 5px;
      margin-bottom: 20px;
    }

    .form-checkbox {
      background-color: $darkLight;

    }

    .form-group-btn {
      display: flex;
      gap: 20px;
    }

    textarea {
      min-height: 200px;
    }
  }


}