@import "../../scss/main";

.header-wrapper {
  height: 100vh;
  background: linear-gradient(rgba($dark, .5), rgba($dark, .5)), url("../../assets/home-header-bg.jpg");
  color: $light;
  @include flex($x: center);
  gap: 20px;
  text-shadow: 0 0 3px $dark;

  .profile-img {
    height: 300px;
    width: 300px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0px 10px -4px $light;
  }

  .text {
    text-align: center;

    span {
      color: $yellow;
    }

    h1 {
      text-transform: uppercase;
      font-size: 2.5rem;
      margin: 10px 0;

      span {
        @media (max-width: 480px) {
          display: block;
          width: 100%;
          height: 2ch;
        }
      }

    }

    h2 {
      text-transform: uppercase;
      margin-bottom: 0;
    }

    p {
      font-size: 1.2rem;
      font-family: $secondaryFont;
      margin-bottom: 20px;
    }
  }
}


