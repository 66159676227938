@import "../../scss/main";

.services {
  article {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;

    }

    div {
      padding: 30px;
      border: 1px solid $gray;
      text-align: center;
      color: $light;

      svg {
        font-size: 4rem;
        color: $danger;
      }

      h3 {
        margin: 10px 0;
        font-size: 1.3rem;
        font-weight: 400;
      }

      p {
        font-family: $secondaryFont;
      }
    }
  }
}