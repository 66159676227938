@import "../../scss/main";

.homeContact {
  background-color: $dark;

  article {
    & > p {
      margin-bottom: 30px;
      color: $light;
    }
  }

  .contact {
    &-info {
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 50px;
      justify-content: space-around;
      margin-bottom: 50px;
      @media (max-width: 767px) {
        grid-template-columns: auto auto auto;
        gap: 10px;
      }
      @media (max-width: 600px) {
        grid-template-columns: 1fr;
      }
      div {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-areas: "icon title" "icon info";
        align-items: center;
        justify-items: start;
        gap: 10px;
        color: $light;
        @media (max-width: 767px) {
          grid-template-areas: "icon title" "info info";
        }
        @media (max-width: 600px) {
          grid-template-areas: "icon title" "icon info";
        }
        svg {
          grid-area: icon;
          color: $danger;
          font-size: 3rem;
          @media (max-width: 767px) {
            font-size: 2rem;
          }
        }

        h3 {
          font-weight: 400;
          grid-area: title;
          font-size: 1.5rem;
        }

        h4 {
          grid-area: info;
          font-family: $secondaryFont;
          font-weight: 400;
        }
      }
    }
  }

  .form {
    width: 100%;
    gap: 20px;
    font-size: 1.1rem;
    display: grid;
    grid-template-areas:
      "name email"
      "subject subject"
      "message message"
      "button button";

    @media (max-width: 767px) {
      grid-template-areas:
        "name"
        "email"
        "subject"
        "message"
        "button";
    }

    div:not(.form-btn) {
      position: relative;
    }

    .form-group {
      @include form-group($dark);
    }

    &-name {
      grid-area: name;
    }

    &-email {
      grid-area: email;
    }

    &-subject {
      grid-area: subject;
    }

    &-message {
      grid-area: message;
      min-height: 300px;
    }

    &-btn {
      grid-area: button;
      justify-self: end;
    }
  }
}
