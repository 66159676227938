@import "var";

@mixin flex($x:space-between,$y:center,$direction:row,$wrap:true ) {
  display: flex;
  flex-direction: $direction;
  @if ($wrap) {
    flex-wrap: wrap;
  } @else {
    flex-wrap: nowrap;
  }
  @if ($direction==row) {
    justify-content: $x;
    align-items: $y;
  } @else {
    justify-content: $y;
    align-items: $x;
  }
}

@mixin form-group($lbl-bg:$dark) {
  position: relative;
  display: flex;
  align-items: center;
  label {
    transition: $transition;
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    padding: 0 5px;
    color: $light;
    user-select: none;
    cursor: text;
  }

  .focused {
    background-color: transparent;
    padding: 10px;
    border: 1px solid $gray;
    font-size: 1.1rem;
    font-family: $primaryFont;
    width: 100%;
    height: 100%;
    color: $light;

    &:focus + label {
      top: 0;
      background-color: $lbl-bg;
      color: $danger;
    }

    &:not(:placeholder-shown) + label {
      color: $danger;
      background-color: $lbl-bg;
      top: 0;
    }
  }

  input:not([type="radio"]) {
    @extend .focused;
  }
  input[type="radio"] {
    height: 1em;
    width: 1em;
    font-size: 24px;

    &:checked {
      & + label {
        color: $danger;
      }
    }

    & + label {
      position: relative;
      top: 0;
      transform: translateY(0);
      cursor: pointer;
    }
  }
  textarea {
    @extend .focused;
    resize: none;

    & + label {
      top: 0;
      transform: translateY(50%);
    }

    &:focus + label {
      top: 0;
      transform: translateY(-50%);
    }

    &:not(:placeholder-shown) + label {
      transform: translateY(-50%);
      top: 0;
    }

  }
}